import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => {
  return (
    <Section id="top">
      <StyledContainer>
        <h1>Política de Privacidade</h1>
        <p>
          Esta política de privacidade descreve como coletamos, usamos e protegemos as informações que você nos fornece ao usar nosso site. Respeitamos e valorizamos a sua privacidade e nos comprometemos a proteger suas informações pessoais.
        </p>
        <h3>Informações que coletamos</h3>
        <p>
          Ao utilizar nosso site, podemos coletar as seguintes informações pessoais:
        </p>
        <ul>
          <li>
            Nome
          </li>
          <li>
            Endereço de e-mail
          </li>
          <li>
            CPF
          </li>
          <li>
            Endereço para envio
          </li>
        </ul>
        <h3>Como usamos suas informações</h3>
        <p>
          As informações pessoais que coletamos são utilizadas exclusivamente para os seguintes propósitos:
        </p>
        <ul>
          <li>
            Processar e enviar os produtos que você adquiriu
          </li>
          <li>
            Comunicar-nos com você sobre sua solicitação
          </li>
          <li>
            Garantir que cumprimos com obrigações legais, como a Lei do Consumidor e a LGPD
          </li>
        </ul>
        <h3>Proteção de suas informações</h3>
        <p>
          Nós nos comprometemos a proteger suas informações pessoais e adotamos medidas de segurança adequadas para garantir a segurança dos dados. Não compartilhamos suas informações com terceiros, exceto quando necessário para cumprir com obrigações legais ou para fornecer os serviços solicitados por você.
        </p>
        <h3>Seus direitos</h3>
        <p>
          Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Se desejar exercer esses direitos ou tiver dúvidas sobre nossa política de privacidade, entre em contato conosco através dos meios de contato fornecidos em nosso site.
        </p>
        <h3>Alterações nesta política</h3>
        <p>
          Reservamo-nos o direito de atualizar ou modificar esta política de privacidade a qualquer momento. Quaisquer alterações significativas serão comunicadas em nosso site.
        </p>
        <p>Ao utilizar nosso site, você concorda com os termos e condições desta política de privacidade.</p>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding-top: 5vh;
  }

  p {
    line-height: 24px;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
  }

  > h3 {
    @media (max-width: ${props => props.theme.screen.md}) {
      text-align: left;
    }
  }
`

const StyledP = styled.p`
  ${props => props.theme.font_size.xxxsmall}

  a {
    color: ${props => props.theme.color.accent};
  }
`
