import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import CallToAction from "../components/sections/calltoaction"
import Body from "../components/sections/privacy"
import Footer from "../components/sections/footer"

const CamisetasPage = () => {
  return (
    <Layout>
    <WhatsButton />
      <SEO
        title=" Sobre Nós [Ouro Estamparia Digital]"
        description="Um pouco da história de como surgiu a Ouro Estamparia Digital."
        pathname="/sobre"
      />
      <Navigation />
      <Body></Body>
      <CallToAction
        msg1="Entre em contato e faça um orçamento"
        sub="falar conosco pelo WhatsApp"
      >
        Encomendar
      </CallToAction>
      <Footer />
    </Layout>
  )
}

export default CamisetasPage
